
					@import './src/sass/variables';
					@import './src/sass/mixins';
				






















































@mixin text-block($color) {
	background: rgba($color, 0.05);
	border-top: 1px solid rgba($color, 0.2) !important;
	border-bottom: 1px solid rgba($color, 0.2);

	.style-icon {
		color: $color;
	}
}

.block-inner:not(.regular) {
	font-weight: $font__semibold;
}

.block-inner::v-deep {
	h2 {
		font-size: 1.3em;
		margin-top: 1.5em;
		margin-bottom: 0.25em;
	}

	h3 {
		font-size: 1em;
		line-height: $line__height;
		margin: 0;
	}

	li {
		margin-bottom: 0.6em;
	}

	:first-child {
		margin-top: 0;
	}

	:last-child {
		margin-bottom: 0;
	}
}

.style-icon {
	position: absolute;
	top: 50%;
	left: $site__padding - 3px;
	transform: translate(-25%, -50%);
}

.instructions {
	@include text-block($color__orange);
}

.help {
	@include text-block($color__green);
}

.warning {
	@include text-block($color__red);
}

.info {
	@include text-block($color__blue);
}

@include breakpoint('small') {
	.style-icon {
		top: $default_padding / 2;
		left: $default_padding / 2;
		transform: none;
		width: 24px;
		height: 24px;
	}
}
